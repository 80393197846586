import { Stack, Button, Center, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

import { withGammaAppProviders } from 'appProviders/main'
import { AsteroidSal } from 'gamma_components/AsteroidSal'
import { CenteredMessageCard } from 'gamma_components/CenteredMessage'

function Error404Page() {
  return (
    <AsteroidSal>
      <CenteredMessageCard
        lede={
          <Trans comment="There is a problem (the user is offline). Could be translated as 'We have a problem...' ">
            Houston, we have a problem...
          </Trans>
        }
        title={<Trans>404 Not Found</Trans>}
        stackProps={{ shadow: 'lg', position: 'relative', zIndex: 2 }}
      >
        <Stack spacing={8}>
          <Text>
            <Trans>We couldn't find the page you're looking for.</Trans>
          </Text>
          <Center>
            <Button
              variant="solid"
              size="lg"
              mt={6}
              onClick={() => {
                window.location.href = '/'
              }}
            >
              <Trans>Go home</Trans>
            </Button>
          </Center>
        </Stack>
      </CenteredMessageCard>
    </AsteroidSal>
  )
}

export default withGammaAppProviders(Error404Page)
